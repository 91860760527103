<template>
  <v-card>
    <v-tabs>
      <v-tab
        v-for="(tab, index) in tabs"
        :key="tab.name"
        :disabled="!activeEstablishment && index !== 0"
        :to="tab.to"
      >
        {{ tab.name.toUpperCase() }}
      </v-tab>
    </v-tabs>
    <v-divider />
    <router-view />
  </v-card>
</template>

<route>
{
"redirect": {"name": "App-MenuCreator-Overview"}
}
</route>

<script>
import { mapGetters } from 'vuex'
import {
  Category,
  CategoryItem,
  Customizer,
  CustomizerItem,
  Item,
  ItemCustomizer,
  Menu,
  MenuCategory
} from '@/store/models'

export default {
  name: 'MenuCreator',
  data () {
    return {
      tabs: [
        { name: 'Overview', to: { name: 'App-MenuCreator-Overview' } },
        { name: 'Menus', to: { name: 'App-MenuCreator-Menus' } },
        { name: 'Categories', to: { name: 'App-MenuCreator-Categories' } },
        { name: 'Items', to: { name: 'App-MenuCreator-Items' } },
        { name: 'Customizers', to: { name: 'App-MenuCreator-Customizers' } }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['activeEstablishment'])
  },
  async created () {
    Menu.api().get()
    MenuCategory.api().get()
    Category.api().get()
    CategoryItem.api().get()
    Item.api().get()
    ItemCustomizer.api().get()
    Customizer.api().get()
    CustomizerItem.api().get()
  }
}
</script>
